import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MediaFormStepFive } from "./steps/media-mix-results";
import { MediaFormStepFour } from "./steps/step-four";
import { MediaFormStepOne } from "./steps/step-one";
import { MediaFormStepThree } from "./steps/step-three";
import { MediaFormStepTwo, ageRanges } from "./steps/step-two";

export interface FormValue {
  campaignGoal: undefined | "Building" | "Nudging" | "Connecting";
  ageRange:
    | undefined
    | "18-24"
    | "25-34"
    | "35-44"
    | "45-54"
    | "55-64"
    | "+65"
    | "All";
  gender: undefined | "Male" | "Female" | "Both";
  category: undefined | "B2B" | "Luxury" | "Retail" | "Fashion" | "Beauty";
  locationType: string;
  location: string;
  target: { type: "budget" | "reach"; value: number } | undefined;
  startDate: undefined | Date;
  endDate: undefined | Date;
}

export const MediaForm = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const openErrorSnackbar = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setIsLoading(false);
      setStep(1);
      setFormValue({
        campaignGoal: undefined,
        ageRange: undefined,
        gender: undefined,
        category: undefined,
        location: "",
        locationType: "",
        target: undefined,
        startDate: new Date(),
        endDate: new Date(),
      });
    }
  }, [open]);
  const [channels, setChannels] = useState([]);
  const [formValue, setFormValue] = useState<FormValue>({
    campaignGoal: undefined,
    ageRange: undefined,
    gender: undefined,
    category: undefined,
    location: "",
    locationType: "",
    target: undefined,
    startDate: new Date(),
    endDate: new Date(),
  });

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(
        "https://us-central1-jellyfish-dcm-dt.cloudfunctions.net/mediaoptimizationtool-test-2",
        {
          method: "POST",
          body: JSON.stringify({
            campaign_objective: formValue.campaignGoal,
            budget: formValue.target,
            country: "US",
            region: formValue.location,
            gender: formValue.gender,
            age: ageRanges.find(
              (ageRange) => ageRange.display === formValue.ageRange
            )?.value,
            campaign_kpi: "Impressions",
          }),
          headers: {
            "Content-Type": "application/json",
          },
          cache: "default",
        }
      );
      setIsLoading(false);
      const json = await res.json();
      setChannels(
        json.channels.map((channel: any) => ({
          ...channel,
          allocation: (channel.allocation * 100).toFixed(2),
        }))
      );
    } catch (e: unknown) {
      openErrorSnackbar();
    }
  };
  const getStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <MediaFormStepOne
            setStep={setStep}
            formValue={formValue}
            setFormValue={setFormValue}
          />
        );
      case 2:
        return (
          <MediaFormStepTwo
            setStep={setStep}
            formValue={formValue}
            setFormValue={setFormValue}
          />
        );
      case 3:
        return (
          <MediaFormStepThree
            setStep={setStep}
            setFormValue={setFormValue}
            formValue={formValue}
          />
        );
      case 4:
        return (
          <MediaFormStepFour
            setStep={setStep}
            setFormValue={setFormValue}
            formValue={formValue}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        );
      case 5:
        return <MediaFormStepFive formValue={formValue} channels={channels} />;
    }
  };

  const getStepName = (step: number) => {
    switch (step) {
      case 1:
        return "Campaign Goal";
      case 2:
        return "Target audience";
      case 3:
        return "Location";
      case 4:
        return "Budget";
      case 5:
        return "Media Mix";
    }
  };
  return (
    <Box>
      <Box
        sx={{
          height: "72px",
          borderBottom: "1px solid #E4E4E4",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {step > 1 && (
          <IconButton
            aria-label="back"
            size="large"
            onClick={() => setStep(step - 1)}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        )}
        {step <= 1 && <Box />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
            }}
          >
            {getStepName(step)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#E9E5FF",
              borderRadius: "100px",
              width: "97px",
              height: "26px",
              marginLeft: "8px",
            }}
          >
            <Typography
              sx={{
                color: "#523FF6",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              Step {step}/5
            </Typography>
          </Box>
        </Box>
        <Box />
      </Box>
      <Box>{getStep(step)}</Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          We could not compute your Media Mix with the current input. Please try
          again.
        </Alert>
      </Snackbar>
    </Box>
  );
};
