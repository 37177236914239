import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputBase,
  Typography,
} from "@mui/material";
import React from "react";
import { FormValue } from "..";
import ContinueBtn from "./continue-btn";
import { SelectLocationItem } from "./step-three";

const StepFourHeader = () => (
  <Box>
    <Typography variant="h1">Budget</Typography>
    <Typography
      sx={{
        width: "430px",
        height: "60px",
        marginTop: "24px",
      }}
      variant="body2"
    >
      What are the budget and timing parameters of this campaign?
    </Typography>
  </Box>
);

const ReachInput = ({ value, setValue }: any) => {
  return (
    <FormControl variant="standard">
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          marginBottom: "16px",
          color: "black",
          fontFamily: "DM Sans",
        }}
      >
        Reach Target
      </Typography>
      <InputBase
        value={value || ""}
        onChange={setValue}
        sx={{
          width: "555px",
          "& > input": {
            marginLeft: "0px",
            paddingLeft: "0px",
          },
        }}
      />
    </FormControl>
  );
};

const BudgetInput = ({ value, setValue }: any) => {
  return (
    <FormControl variant="standard">
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          marginBottom: "16px",
          color: "black",
          fontFamily: "DM Sans",
        }}
      >
        Client Budget
      </Typography>
      <InputBase
        value={value || ""}
        onChange={setValue}
        sx={{
          width: "555px",
          "& > input": {
            marginLeft: "0px",
            paddingLeft: "0px",
          },
        }}
        startAdornment={
          <InputAdornment
            sx={{
              width: "30px",
              justifyContent: "center",
              "& > p": {
                color: "#808080",
                fontWeight: 400,
                fontSize: "18px",
              },
            }}
            position="start"
          >
            $
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

// const CampaignPeriod = (props: any) => (
//   <Box>
//     <Typography
//       sx={{
//         fontSize: "18px",
//         fontWeight: "500",
//         marginBottom: "16px",
//         color: "black",
//         fontFamily: "DM Sans",
//       }}
//     >
//       Campaign Period
//     </Typography>
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "row",
//         border: "2px solid #E6E6E6",
//         borderRadius: "15px",
//         width: "555px",
//         height: "72px",
//       }}
//     >
//       <DatePicker
//         value={props.startDate === undefined ? null : props.startDate}
//         open={props.IsStartDatePickerOpen}
//         onAccept={props.closeStartDatePicker}
//         onChange={(newValue) => {
//           props.setStartDate(newValue);
//         }}
//         renderInput={({ inputRef, inputProps, InputProps }) => (
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               width: "250px",
//             }}
//           >
//             <InputBase
//               sx={{
//                 width: "200px",
//                 border: "none",
//                 marginLeft: "15px",
//               }}
//               ref={inputRef}
//               value={inputProps === undefined ? "" : inputProps.value}
//               startAdornment={
//                 <InputAdornment position="end">
//                   <IconButton
//                     sx={{ color: "#523FF6" }}
//                     onClick={props.handleOpenStartDatePicker}
//                     edge="start"
//                   >
//                     <CalendarTodayIcon />
//                   </IconButton>
//                 </InputAdornment>
//               }
//             />
//           </Box>
//         )}
//       />
//       <DatePicker
//         value={props.endDate === undefined ? null : props.endDate}
//         open={props.IsEndDatePickerOpen}
//         onAccept={props.closeEndDatePicker}
//         onChange={(newValue) => {
//           props.setEndDate(newValue);
//         }}
//         renderInput={({ inputRef, inputProps, InputProps }) => (
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               width: "250px",
//               borderLeft: "1px solid #CCCCCC",
//             }}
//           >
//             <InputBase
//               sx={{
//                 width: "200px",
//                 border: "none",
//                 marginLeft: "15px",
//               }}
//               ref={inputRef}
//               value={inputProps === undefined ? "From Date" : inputProps.value}
//               startAdornment={
//                 <InputAdornment position="end">
//                   <IconButton
//                     sx={{ color: "#523FF6" }}
//                     onClick={props.handleOpenEndDatePicker}
//                     edge="start"
//                   >
//                     <CalendarTodayIcon />
//                   </IconButton>
//                 </InputAdornment>
//               }
//             />
//           </Box>
//         )}
//       />
//     </Box>
//   </Box>
// );

interface MediaFormStepFourProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
  onSubmit: any;
  isLoading: boolean;
}

export const MediaFormStepFour = ({
  setStep,
  formValue,
  setFormValue,
  onSubmit,
  isLoading,
}: MediaFormStepFourProps) => {
  //const [IsStartDatePickerOpen, setIsStartDatePickerOpen] =
  //  React.useState(false);

  //  const handleOpenStartDatePicker = (e: any) => {
  //    setIsStartDatePickerOpen(!IsStartDatePickerOpen);
  //  };
  //
  //  const closeStartDatePicker = () => {
  //    setIsStartDatePickerOpen(false);
  //  };

  //const [IsEndDatePickerOpen, setIsEndDatePickerOpen] = React.useState(false);

  //  const handleOpenEndDatePicker = (e: any) => {
  //    setIsEndDatePickerOpen(!IsEndDatePickerOpen);
  //  };
  //
  //  const closeEndDatePicker = () => {
  //    setIsEndDatePickerOpen(false);
  //  };

  const onClick = async () => {
    await onSubmit();
    setStep(5);
  };

  const getInput = (formValue: FormValue) => {
    const target = formValue.target;
    if (target === undefined) {
      return null;
    }
    if (target.type === "budget") {
      return (
        <BudgetInput
          value={target.value}
          setValue={(e: any) =>
            setFormValue({
              ...formValue,
              target: { ...target, value: e.target.value },
            })
          }
        ></BudgetInput>
      );
    }
    if (target.type === "reach") {
      return (
        <ReachInput
          value={target.value}
          setValue={(e: any) =>
            setFormValue({
              ...formValue,
              target: { ...target, value: e.target.value },
            })
          }
        ></ReachInput>
      );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginLeft="80px"
      marginTop="64px"
      gap="32px"
    >
      <StepFourHeader />

      <Box display="flex" flexDirection="column" gap="32px">
        <SelectLocationItem
          locationTitle="What is your target?"
          value={formValue.target?.type || ""}
          setValue={(value: any) =>
            setFormValue({
              ...formValue,
              target: { type: value, value: 0 },
            })
          }
          choices={["budget", "reach"]}
          isSelected={formValue.target !== undefined}
        ></SelectLocationItem>
        {getInput(formValue)}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            width: "201px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <ContinueBtn
          disabled={
            formValue.target === undefined ||
            formValue.startDate === undefined ||
            formValue.endDate === undefined
          }
          onClick={onClick}
        />
      )}
    </Box>
  );
};
