import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

// const frmt = Intl.NumberFormat("eu-US");
interface MediaMixTableItemProps {
  channel: string;
  allocation: number;
  spend: number;
  impressions: number;
  reach: number;
}
interface MediaMixTableProps {
  items: MediaMixTableItemProps[];
}
const MediaMixTableItem = ({
  channel,
  allocation,
  spend,
  impressions,
  reach,
}: MediaMixTableItemProps) => (
  <TableRow
    sx={{
      "& > *": { fontWeight: 500 },
      "&:last-child > *": { borderBottom: "none" },
    }}
  >
    <TableCell component="th" scope="row">
      {channel}
    </TableCell>
    <TableCell>{allocation} %</TableCell>
    {/*     <TableCell>$ {frmt.format(spend)}</TableCell> */}
    {/*     <TableCell>{impressions}</TableCell> */}
    {/* <TableCell>{reach}</TableCell> */}
  </TableRow>
);

const MediaMixTable = ({ items }: MediaMixTableProps) => {
  return (
    <Table
      sx={{
        maxWidth: "400px",
      }}
    >
      <TableHead>
        <TableRow sx={{ "& > th": { color: "#808080", width: "200px" } }}>
          <TableCell>Channel</TableCell>
          <TableCell>Channel % Allocation</TableCell>
          {/*           <TableCell>Recommended Budget</TableCell> */}
          {/*           <TableCell>Historical mix</TableCell> */}
          {/* <TableCell>Something</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(({ channel, allocation, spend, impressions, reach }, i) => (
          <MediaMixTableItem
            key={i}
            channel={channel}
            allocation={allocation}
            spend={spend}
            impressions={impressions}
            reach={reach}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export const MediaFormStepFive = ({ formValue, channels }: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const labels = channels.map((channel: any) => channel.channel);
  const outputData = channels.map((channel: any) => channel.allocation);

  const data = {
    labels,
    datasets: [
      {
        label: "Media Mix",
        data: outputData,
        backgroundColor: [
          "#3E00E1",
          "#4A5DE2",
          "#6A79E2",
          "#8C97E3",
          "#A6ADE2",
          "#C3C7E4",
          "#DFE0E7",
        ],
        borderColor: [
          "#3E00E1",
          "#4A5DE2",
          "#6A79E2",
          "#8C97E3",
          "#A6ADE2",
          "#C3C7E4",
          "#DFE0E7",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="0 80px"
      marginTop="64px"
      gap="32px"
    >
      <Box>
        <Typography variant="h1">Media Mix</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          border: "2px solid #E3E3E3",
          borderRadius: "30px",
          padding: "15px",
          gap: "45px",
        }}
      >
        <Box
          sx={{
            height: "400px",
            width: "400px",
            marginRight: "25px",
          }}
        >
          <Pie
            data={data}
            options={{
              plugins: {
                legend: {
                  position: "right",
                },
              },
            }}
            height={200}
            width={200}
          />
        </Box>
        <MediaMixTable items={channels} />
      </Box>
    </Box>
  );
};
