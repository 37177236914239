import { Box, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormValue } from "..";
import ContinueBtn from "./continue-btn";
import { regions } from "./countries";

interface LocationSelectItemProps {
  locationTitle: string;
  isSelected: boolean;
  value: string;
  setValue: any;
  choices: string[];
}

export const SelectLocationItem = ({
  locationTitle,
  value,
  setValue,
  isSelected,
  choices,
}: LocationSelectItemProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "152px",
        margin: "16px 0",
        border: `${isSelected ? "4px" : "2px"} solid ${
          isSelected ? "#523FF6" : "#E6E6E6"
        }`,
        borderRadius: "15px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#F6F5FE",
        },
      }}
    >
      <Typography
        sx={{
          color: isSelected ? "#000000" : "#333333",
          padding: "20px 24px",
          fontSize: "24px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        {locationTitle}
      </Typography>
      {
        <Select
          value={value}
          defaultValue={""}
          onChange={handleChange}
          sx={{
            width: "360px",
            margin: "0 24px 12px 24px",
          }}
        >
          <MenuItem value={undefined}>
            <em>None</em>
          </MenuItem>
          {choices.map((country) => (
            <MenuItem key={country} id={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </Select>
      }
    </Box>
  );
};

interface MediaFormStepThreeProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
}

export const MediaFormStepThree = ({
  setStep,
  formValue,
  setFormValue,
}: MediaFormStepThreeProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="0 80px"
      marginTop="64px"
      gap="32px"
    >
      <Box>
        <Typography variant="h1">Location</Typography>
        <Typography
          sx={{
            width: "430px",
            height: "60px",
            marginTop: "24px",
          }}
          variant="body2"
        >
          Select the geographic priorities for this campaign:
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          columnGap: "12px",
        }}
      >
        <SelectLocationItem
          value={formValue.location}
          choices={regions}
          setValue={(value: any) =>
            setFormValue({
              ...formValue,
              location: value,
              locationType: "region",
            })
          }
          locationTitle={"Region"}
          isSelected={formValue.locationType === "region"}
        />
        {/*         <SelectLocationItem
          value={formValue.location}
          choices={states}
          setValue={(value: any) =>
            setFormValue({
              ...formValue,
              location: value,
              locationType: "state",
            })
          }
          locationTitle={"State"}
          isSelected={formValue.locationType === "state"}
        />
        <SelectLocationItem
          value={formValue.location}
          choices={dmas}
          setValue={(value: any) =>
            setFormValue({ ...formValue, location: value, locationType: "dma" })
          }
          locationTitle={"DMA"}
          isSelected={formValue.locationType === "dma"}
        /> */}
      </Box>

      <ContinueBtn
        disabled={formValue.location === undefined}
        onClick={() => setStep(4)}
      />
    </Box>
  );
};
