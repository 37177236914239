import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { MediaForm } from "./media-form";
import reportWebVitals from "./reportWebVitals";

let theme = createTheme({
  palette: {
    primary: {
      main: "#523FF6",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: "2px solid #E6E6E6",
          borderRadius: "15px",
          transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
          ]),
          "label + &": {
            marginTop: "24px",
          },
          "&.MuiFocused": {
            boxShadow: `${alpha(
              theme.palette.primary.main,
              0.25
            )} 0 0 0 0.1rem`,
            borderColor: theme.palette.primary.main,
          },
        },
        inputAdornedStart: {
          marginLeft: "16px",
        },
        input: {
          position: "relative",
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "10px",
          width: "555px",
          height: "68px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "201px",
          height: "60px",
          background: "#523FF6",
          borderRadius: "30px",
          color: "white",
          fontFamily: "DM Sans",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "19px",
          textTransform: "none",
          "&:hover": {
            background: "#523FF6",
          },
          "&.Mui-disabled": {
            background: "#bab2fb",
            color: "white",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ["DM Sans"].join(","),
    h1: {
      fontSize: "48px",
      fontWeight: "500",
      fontFamily: ["DM Sans"].join(","),
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: ["DM Sans"].join(","),
    },
    body2: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#808080",
      fontFamily: ["DM Sans"].join(","),
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl">
          <MediaForm />
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
