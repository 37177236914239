import { Box, Typography } from "@mui/material";
import { FormValue } from "..";
import ContinueBtn from "./continue-btn";

//  Page 1
interface CampaignTypeBoxProps {
  title: string;
  subtitle: string;
  content: string;
  isSelected: boolean;
  onClick: any;
}
const CampaignTypeBox = ({
  title,
  subtitle,
  content,
  isSelected,
  onClick,
}: CampaignTypeBoxProps) => (
  <Box
    sx={{
      width: "100%",
      height: "342px",
      border: isSelected ? "4px solid #523FF6" : "2px solid #E3E3E3",
      borderRadius: "30px",
      paddingLeft: "32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 100,
      "&:hover": {
        backgroundColor: "#F6F5FE",
      },
    }}
    onClick={onClick}
  >
    <Box marginBottom="30px">
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "32px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#808080",
          fontWeight: "500",
          fontSize: "18px",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
    <Box
      sx={{
        width: "273px",
      }}
    >
      <Typography variant="body1">{content}</Typography>
    </Box>
  </Box>
);

interface MediaFormStepOneProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
}

export const MediaFormStepOne = ({
  setStep,
  formValue,
  setFormValue,
}: MediaFormStepOneProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="80px"
      marginTop="64px"
      gap="32px"
    >
      <Box>
        <Typography variant="h1">Campaign Goal</Typography>
        <Typography
          sx={{
            width: "430px",
            height: "60px",
            marginTop: "24px",
          }}
          variant="body2"
        >
          Select the primary objective of this campaign:
        </Typography>
      </Box>
      <Box display="flex" height="350px" gap="16px">
        <CampaignTypeBox
          isSelected={formValue.campaignGoal === "Building"}
          onClick={() => {
            setFormValue({ ...formValue, campaignGoal: "Building" });
          }}
          title="Building"
          subtitle="Feel"
          content="Build and refresh brand memories & associations to help the brand come to mind in decision/buying moments"
        />
        <CampaignTypeBox
          isSelected={formValue.campaignGoal === "Nudging"}
          onClick={() =>
            setFormValue({ ...formValue, campaignGoal: "Nudging" })
          }
          title="Nudging"
          subtitle="Think"
          content="Nudge the decisions of in-market prospects by refreshing relevant associations closer to purchase"
        />
        <CampaignTypeBox
          isSelected={formValue.campaignGoal === "Connecting"}
          onClick={() =>
            setFormValue({ ...formValue, campaignGoal: "Connecting" })
          }
          title="Connecting"
          subtitle="Do"
          content="Connect buyers to brands/products with navigational signposts that make them easy to find & buy"
        />
      </Box>

      <ContinueBtn
        disabled={formValue.campaignGoal === undefined}
        onClick={() => setStep(2)}
      />
    </Box>
  );
};
