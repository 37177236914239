export const dmas = [
  "Portland-Auburn,ME",
  "New York,NY",
  "Binghamton,NY",
  "Macon,GA",
  "Philadelphia,PA",
  "Detroit,MI",
  "Boston,MA-Manchester,NH",
  "Savannah,GA",
  "Pittsburgh,PA",
  "Ft. Wayne,IN",
  "Cleveland-Akron (Canton),OH",
  "Washington,DC (Hagerstown,MD)",
  "Baltimore,MD",
  "Flint-Saginaw-Bay City,MI",
  "Buffalo,NY",
  "Cincinnati,OH",
  "Erie,PA",
  "Charlotte,NC",
  "Greensboro-High Point-Winston Salem,NC",
  "Charleston,SC",
  "Augusta,GA",
  "Providence,RI-New Bedford,MA",
  "Columbus,GA",
  "Burlington,VT-Plattsburgh,NY",
  "Atlanta,GA",
  "Albany,GA",
  "Utica,NY",
  "Indianapolis,IN",
  "Miami-Ft. Lauderdale,FL",
  "Louisville,KY",
  "Tallahassee,FL-Thomasville,GA",
  "Tri-Cities,TN-VA",
  "Albany-Schenectady-Troy,NY",
  "Hartford & New Haven,CT",
  "Orlando-Daytona Beach-Melbourne,FL",
  "Columbus,OH",
  "Youngstown,OH",
  "Bangor,ME",
  "Rochester,NY",
  "Tampa-St Petersburg (Sarasota),FL",
  "Traverse City-Cadillac,MI",
  "Lexington,KY",
  "Dayton,OH",
  "Springfield-Holyoke,MA",
  "Norfolk-Portsmouth-Newport News,VA",
  "Greenville-New Bern-Washington,NC",
  "Columbia,SC",
  "Toledo,OH",
  "West Palm Beach-Ft. Pierce,FL",
  "Watertown,NY",
  "Wilmington,NC",
  "Lansing,MI",
  "Presque Isle,ME",
  "Marquette,MI",
  "Wheeling,WV-Steubenville,OH",
  "Syracuse,NY",
  "Richmond-Petersburg,VA",
  "Knoxville,TN",
  "Lima,OH",
  "Bluefield-Beckley-Oak Hill,WV",
  "Raleigh-Durham (Fayetteville),NC",
  "Jacksonville,FL",
  "Grand Rapids-Kalamazoo-Battle Creek,MI",
  "Charleston-Huntington,WV",
  "Elmira,NY",
  "Harrisburg-Lancaster-Lebanon-York,PA",
  "Greenville-Spartanburg,SC-Asheville,NC-Anderson,SC",
  "Harrisonburg,VA",
  "Florence-Myrtle Beach,SC",
  "Ft. Myers-Naples,FL",
  "Roanoke-Lynchburg,VA",
  "Johnstown-Altoona,PA",
  "Chattanooga,TN",
  "Salisbury,MD",
  "Wilkes Barre-Scranton,PA",
  "Terre Haute,IN",
  "Lafayette,IN",
  "Alpena,MI",
  "Charlottesville,VA",
  "South Bend-Elkhart,IN",
  "Gainesville,FL",
  "Zanesville,OH",
  "Parkersburg,WV",
  "Clarksburg-Weston,WV",
  "Corpus Christi,TX",
  "Chicago,IL",
  "Joplin,MO-Pittsburg,KS",
  "Columbia-Jefferson City,MO",
  "Topeka,KS",
  "Dothan,AL",
  "St. Louis,MO",
  "Rockford,IL",
  "Rochester,MN-Mason City,IA-Austin,MN",
  "Shreveport,LA",
  "Minneapolis-St. Paul,MN",
  "Kansas City,MO",
  "Milwaukee,WI",
  "Houston,TX",
  "Springfield,MO",
  "New Orleans,LA",
  "Dallas-Ft. Worth,TX",
  "Sioux City,IA",
  "Waco-Temple-Bryan,TX",
  "Victoria,TX",
  "Wichita Falls,TX & Lawton,OK",
  "Monroe,LA-El Dorado,AR",
  "Birmingham,AL",
  "Ottumwa,IA-Kirksville,MO",
  "Paducah,KY-Cape Girardeau,MO-Harrisburg-Mount Vernon,IL",
  "Odessa-Midland,TX",
  "Amarillo,TX",
  "Austin,TX",
  "Harlingen-Weslaco-Brownsville-McAllen,TX",
  "Cedar Rapids-Waterloo-Iowa City & Dubuque,IA",
  "St. Joseph,MO",
  "Jackson,TN",
  "Memphis,TN",
  "San Antonio,TX",
  "Lafayette,LA",
  "Lake Charles,LA",
  "Alexandria,LA",
  "Greenwood-Greenville,MS",
  "Champaign & Springfield-Decatur,IL",
  "Evansville,IN",
  "Oklahoma City,OK",
  "Lubbock,TX",
  "Omaha,NE",
  "Panama City,FL",
  "Sherman,TX-Ada,OK",
  "Green Bay-Appleton,WI",
  "Nashville,TN",
  "San Angelo,TX",
  "Abilene-Sweetwater,TX",
  "Madison,WI",
  "Ft. Smith-Fayetteville-Springdale-Rogers,AR",
  "Tulsa,OK",
  "Columbus-Tupelo-West Point,MS",
  "Peoria-Bloomington,IL",
  "Duluth,MN-Superior,WI",
  "Wichita-Hutchinson,KS",
  "Des Moines-Ames,IA",
  "Davenport,IA-Rock Island-Moline,IL",
  "Mobile,AL-Pensacola (Ft. Walton Beach),FL",
  "Minot-Bismarck-Dickinson(Williston),ND",
  "Huntsville-Decatur (Florence),AL",
  "Beaumont-Port Arthur,TX",
  "Little Rock-Pine Bluff,AR",
  "Montgomery (Selma),AL",
  "La Crosse-Eau Claire,WI",
  "Wausau-Rhinelander,WI",
  "Tyler-Longview(Lufkin & Nacogdoches),TX",
  "Hattiesburg-Laurel,MS",
  "Meridian,MS",
  "Baton Rouge,LA",
  "Quincy,IL-Hannibal,MO-Keokuk,IA",
  "Jackson,MS",
  "Lincoln & Hastings-Kearney,NE",
  "Fargo-Valley City,ND",
  "Sioux Falls(Mitchell),SD",
  "Jonesboro,AR",
  "Bowling Green,KY",
  "Mankato,MN",
  "North Platte,NE",
  "Anchorage,AK",
  "Honolulu,HI",
  "Fairbanks,AK",
  "Biloxi-Gulfport,MS",
  "Juneau,AK",
  "Laredo,TX",
  "Denver,CO",
  "Colorado Springs-Pueblo,CO",
  "Phoenix,AZ",
  "Butte-Bozeman,MT",
  "Great Falls,MT",
  "Billings,MT",
  "Boise,ID",
  "Idaho Falls-Pocatello,ID",
  "Cheyenne,WY-Scottsbluff,NE",
  "Twin Falls,ID",
  "Missoula,MT",
  "Rapid City,SD",
  "El Paso,TX",
  "Helena,MT",
  "Casper-Riverton,WY",
  "Salt Lake City,UT",
  "Yuma,AZ-El Centro,CA",
  "Grand Junction-Montrose,CO",
  "Tucson (Sierra Vista),AZ",
  "Albuquerque-Santa Fe,NM",
  "Glendive,MT",
  "Bakersfield,CA",
  "Eugene,OR",
  "Eureka,CA",
  "Los Angeles,CA",
  "Palm Springs,CA",
  "San Francisco-Oakland-San Jose,CA",
  "Yakima-Pasco-Richland-Kennewick,WA",
  "Reno,NV",
  "Medford-Klamath Falls,OR",
  "Seattle-Tacoma,WA",
  "Portland,OR",
  "Bend,OR",
  "San Diego,CA",
  "Monterey-Salinas,CA",
  "Las Vegas,NV",
  "Santa Barbara-Santa Maria-San Luis Obispo,CA",
  "Sacramento-Stockton-Modesto,CA",
  "Fresno-Visalia,CA",
  "Chico-Redding,CA",
  "Spokane,WA",
];

export const regions = [
  "Northeast",
  "Midwest",
  "Southeast",
  "Southwest",
  "West",
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "American Samoa",
];
