import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface ContinueBtnProps {
  disabled?: boolean;
  onClick: () => void;
}

const ContinueBtn = ({ disabled, onClick }: ContinueBtnProps) => {
  return (
    <Button
      variant="contained"
      endIcon={<ArrowForwardIcon />}
      disabled={disabled}
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0 30px",
      }}
    >
      Continue
    </Button>
  );
};

export default ContinueBtn;
