import { Box, Typography } from "@mui/material";
import { FormValue } from "..";
import ContinueBtn from "./continue-btn";

interface TargetAudienceSelectItemProps {
  value: string;
  isSelected: boolean;
  onClick: any;
}
const TargetAudienceSelectItem = ({
  value,
  isSelected,
  onClick,
}: TargetAudienceSelectItemProps) => (
  <Box
    onClick={onClick}
    sx={{
      width: "100%",
      height: "70px",
      margin: "16px 0",
      border: `${isSelected ? "4px" : "2px"} solid ${
        isSelected ? "#523FF6" : "#E6E6E6"
      }`,
      borderRadius: "15px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F6F5FE",
      },
    }}
  >
    <Typography
      sx={{
        color: isSelected ? "#000000" : "#333333",
        padding: "20px 24px",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "24px",
      }}
    >
      {value}
    </Typography>
  </Box>
);
interface TargetAudienceBoxProps {
  title: string;
  value: string | undefined;
  setValue: any;
  content: string[];
}
const TargetAudienceSelectBox = ({
  setValue,
  value,
  title,
  content,
}: TargetAudienceBoxProps) => (
  <Box>
    <Typography sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "24px" }}>
      {title}
    </Typography>
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: "12px",
      }}
    >
      {content.map((content, i) => (
        <TargetAudienceSelectItem
          onClick={() => setValue(content)}
          key={i}
          value={content}
          isSelected={content === value}
        />
      ))}
    </Box>
  </Box>
);

export const ageRanges = [
  { display: "18-24", value: "18_to_24" },
  { display: "25-34", value: "25_to_34" },
  { display: "35-44", value: "35_to_44" },
  { display: "45-54", value: "45_to_54" },
  { display: "55-64", value: "55_to_64" },
  { display: "+65", value: "65_over" },
];

interface MediaFormStepTwoProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
}

export const MediaFormStepTwo = ({
  setStep,
  formValue,
  setFormValue,
}: MediaFormStepTwoProps) => {
  return (
    <Box display="flex" flexDirection="column" margin="64px 80px" gap="32px">
      <Box>
        <Typography variant="h1">Target audience</Typography>
        <Typography
          sx={{
            width: "430px",
            height: "60px",
            marginTop: "24px",
          }}
          variant="body2"
        >
          Select the target audience for this campaign:
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="32px">
        <TargetAudienceSelectBox
          value={formValue.ageRange}
          setValue={(value: any) =>
            setFormValue({ ...formValue, ageRange: value })
          }
          title="Age range"
          content={ageRanges.map((ageRange) => ageRange.display)}
        />
        <TargetAudienceSelectBox
          value={formValue.gender}
          setValue={(value: any) =>
            setFormValue({ ...formValue, gender: value })
          }
          title="Gender"
          content={["Female", "Male"]}
        />
        {/*         <TargetAudienceSelectBox
          value={formValue.category}
          setValue={(value: any) =>
            setFormValue({ ...formValue, category: value })
          }
          title="Category"
          content={["B2B", "Luxury", "Retail", "Fashion", "Beauty"]}
        />*/}
      </Box>

      <ContinueBtn
        disabled={
          formValue.ageRange === undefined || formValue.gender === undefined
        }
        onClick={() => setStep(3)}
      />
    </Box>
  );
};
